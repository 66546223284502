import { createThemeVars, ApricotTheme, ThemeProvider, OrbitTheme } from "@sharegate/orbit-ui";
import { StrictMode } from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';

const OvercastTheme: OrbitTheme = {
    ...ApricotTheme,
    name: "overcast",
    colors: {
        ...ApricotTheme.colors,
        common: {
            ...ApricotTheme.colors.common,
            primary: [
                "#E4F2FF",
                "#BCE0FF",
                "#90CBFF",
                "#63B6FF",
                "#41A6FF",
                "#2096FF",
                "#1C8EFF",
                "#1883FF",
                "#1379FF",
                "#0B68FF"
            ]
        },
      }
    }

createThemeVars([OvercastTheme]);

render(
  <StrictMode>
    <ThemeProvider theme={OvercastTheme} colorScheme="light">
      <App />
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);
