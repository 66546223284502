import { useState, useRef, useEffect } from "react";

interface UseTimeoutHandler {
    start: () => any;
    clear: () => any;
    isTimeoutActive: boolean;
}

export const useTimeout = (callback: () => void, timeoutDelayMs: number = 0): UseTimeoutHandler => {
  const [isTimeoutActive, setIsTimeoutActive] = useState(false);
  const savedRefCallback = useRef<() => any>();

  useEffect(() => {
    savedRefCallback.current = callback;
  }, [callback]);

  function clear() {
    setIsTimeoutActive(false);
  }
  function start() {
    setIsTimeoutActive(true);
  }

  useEffect(() => {
    function callbackAndClear() {
        savedRefCallback.current && savedRefCallback.current();
        clear();
    }

    if (isTimeoutActive) {
      const timeout = setTimeout(callbackAndClear, timeoutDelayMs);
      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [isTimeoutActive, timeoutDelayMs]);

  return { clear, start, isTimeoutActive };
}