import { ButtonProps } from "@sharegate/orbit-ui";
import { useTimeout } from "../hooks";
import { MouseEvent } from "react";
import copy from "copy-to-clipboard";

const COPIED_TEXT_ON_BUTTON_DELAY = 2200;

interface CopyToClipboardButtonProps extends ButtonProps {
    contentToCopy: string;
}

export const CopyToClipboardButton = ({contentToCopy, children, onClick, ...props}: CopyToClipboardButtonProps) => {
    const { start: setIsTextCopied, isTimeoutActive: isTextCopied } = useTimeout(() => {}, COPIED_TEXT_ON_BUTTON_DELAY);

    const handleCopyButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        setIsTextCopied();

        copy(contentToCopy);
        
        if (onClick) {
            onClick(event);
        }
    };

    return <button className="button primary-button" onClick={handleCopyButtonClick}>
        {isTextCopied ? <>Copied!</> : children}
    </button>
}