import { TenantFinder } from "./TenantFinder";
import { CTAs } from "./components/CTAs";
import ArrowRight from './assets/arrow-right.svg';
import ShareGateLogo from "./assets/sharegate-logo.svg";
import MainBackground from "./assets/main-background.svg";

function App() {
  return (
    <div className="main-container flex flex-column items-center pv7 ph6 ph13-ns pb13-ns min-vh-100 w-100 overflow-hidden">
        <div className="button-container button-nav">
            <a className="button outline-button" href="https://home.sharegate.com/register" rel="noreferrer" target="_blank">Try ShareGate for free&nbsp;<img className="arrow-right" src={ArrowRight} alt="arrow right" /></a>
        </div>
        <div className="flex flex-column items-center relative w-100">
            <a className="mb-100" href="https://sharegate.com/" rel="noreferrer" target="_blank">
                <img src={ShareGateLogo} alt="ShareGate logo" height="76" width="250" className="w-250px-ns" />
            </a>
            <TenantFinder />
            <CTAs />
            <img src={MainBackground} alt="Tron background" className="main-background" />
        </div>
    </div>
  );
}

export default App;
